import axios from 'axios';
import router from '@/router';
import Clipboard from 'clipboard';
import { Toast, Indicator } from 'mint-ui';
import des from './des.js';
import md5 from 'js-md5';

// const fileserver = "https://file.caredaily.com";

var baseurl = process.env.VUE_APP_APIs;
var refresh_token_api = process.env.VUE_APP_API;

var FrontEnd = process.env.VUE_APP_FONTEND;

const service = axios.create({
	baseURL: baseurl,
	timeout: 60000
});

const d = 'Ckil845@#$%^_=258Lo{,.}';

service.interceptors.request.use(
	(config) => {
		Indicator.open('加载中...');
		// if(config.url.indexOf('actmonitor')==-1){
		//     Indicator.open("加载中...")
		//   }
		if (sessionStorage.getItem('tokentwo')) {
			// 判断是否存在token，如果存在的话，则每个http header都加上token
			// config.headers.Authorization = JSON.parse(des.decryptByDES(sessionStorage.getItem('tokentwo'),d)).token;
			config.headers.Authorization = sessionStorage.getItem('tokentwo');
		}
		return config;
	},
	(err) => {
		return Promise.reject(err);
	}
);

service.interceptors.response.use(
	(response) => {
		Indicator.close();
		if (response.data && response.data.errmsg && response.data.errmsg == '系统维护中，请稍后再试') {
			router.push('/system');
		}
		return response;
	},
	(error) => {
		Indicator.close();
		if (error.response.status == '401') {
			router.push('/login')
			// let refreshData = {};
			// if (sessionStorage.getItem('refresh')) {
			// 	refreshData = {
			// 		refresh_token: des.decryptByDES(sessionStorage.getItem('refresh'), d)
			// 	};
			// }
			// service.post('http://192.168.80.20/usercenter/refresh', refreshData).then(res => {
			// 	if (res.data.ok) {
			// 		sessionStorage.setItem('tokentwo', res.data.data.token);
			// 		sessionStorage.setItem('refresh', des.encryptByDES(res.data.refresh_token, d));
			// 	}
			// }).catch(err => {
			// 	console.log('err99999', err)
			// })
			return Promise.reject(error.response)
		} else if (error.response.status == '400') {
			if(error.response.data.message!='账号异常,可联系客服咨询！'){
				Toast(error.response.data.message)
			}
			return Promise.reject(error.response)
		} else if (error.response.status == '502') {
			return Toast('服务器错误-502')
		} else {
			if (error.response.data && error.response.data.message && error.response.data.message == '系统维护中，请稍后再试') {
				router.push('/system');
			}
		   Toast(error.response.data.message || error.response.data.reason)
		}
    	return error.response// 返回接口返回的错误信息
	}
);

// 错误 回到登录页

function handleErr() {
	sessionStorage.clear();
	//重定向
	router.push('/login');
}

class http {
	static baseURL = baseurl;
	static frontEnd = FrontEnd;

	static promotionUrl() {
		return service.defaults.baseURL;
	}

	static setUrl(str) {
		service.defaults.baseURL = str;
	}

	static post(url, data, conf) {
		let that = this
		return new Promise(function (resolve, reject) {
			let refreshData = {};
			if (sessionStorage.getItem('refresh')) {
				refreshData = {
					refresh_token: des.decryptByDES(sessionStorage.getItem('refresh'), d)
				};
			}
			service
				.post(url, data, conf)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					that.errResponse(err, refreshData, resolve, reject, 'post', url, data)
				});
		});
	}

	static get(url, params) {
		let that = this
		return new Promise(function (resolve, reject) {
			let refreshData = {};
			if (sessionStorage.getItem('refresh')) {
				refreshData = {
					refresh_token: des.decryptByDES(sessionStorage.getItem('refresh'), d)
				};
			}
			service
				.get(url, {params:params})
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					that.errResponse(err, refreshData, reject, 'get', url, params)
				});
		});
	}
	static put(url, data) {
		let that = this
		return new Promise(function (resolve, reject) {
			let refreshData = {};
			if (sessionStorage.getItem('refresh')) {
				refreshData = {
					refresh_token: des.decryptByDES(sessionStorage.getItem('refresh'), d)
				};
			}
			service
				.put(url, data)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					that.errResponse(err, refreshData, resolve, reject, 'put', url, data)
				});
		});
	}

	static delete(url) {
		let that = this
		return new Promise(function (resolve, reject) {
			let refreshData = {};
			if (sessionStorage.getItem('refresh')) {
				refreshData = {
					refresh_token: des.decryptByDES(sessionStorage.getItem('refresh'), d)
				};
			}
			service
				.delete(url)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					that.errResponse(err, refreshData, resolve, reject, 'delete', url)
				});
		});
	}

	static patch(url, data) {
		let that = this
		return new Promise(function (resolve, reject) {
			let refreshData = {};
			if (sessionStorage.getItem('refresh')) {
				refreshData = {
					refresh_token: des.decryptByDES(sessionStorage.getItem('refresh'), d)
				};
			}
			service
				.patch(url, data)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					that.errResponse(err, refreshData, resolve, reject, 'patch', url, data)
				});
		});
	}

	// 请求失败公共方法
	static errResponse(err, refreshData, resolve, reject, type, url, data) {
		if (err && err.status == 401) {
			//如果token过期，用refresh_token请求新token
			service.post(refresh_token_api + 'usercenter/refresh', refreshData).then((res) => {
				if (res.data.ok == true) {
					sessionStorage.setItem('tokentwo', res.data.data.token);
					sessionStorage.setItem('refresh', des.encryptByDES(res.data.refresh_token, d));
					//刷新token后重新请求
					if (type === 'get') {
						service.get(url).then((res) => {
							resolve(res.data);
						})
						.catch((err) => {
							reject(err);
						});
					} else if (type === 'post') {
						service.post(url, data).then((res) => {
							resolve(res.data);
						})
						.catch((err) => {
							reject(err);
						});

					} else if (type === 'put') {
						service.put(url, data).then((res) => {
							resolve(res.data);
						})
						.catch((err) => {
							reject(err);
						});
					} else if (type === 'delete') {
						service.delete(url).then((res) => {
							resolve(res.data);
						})
						.catch((err) => {
							reject(err);
						});
					} else if (type === 'patch') {
						service.patch(url, data).then((res) => {
							resolve(res.data);
						}).catch((err) => {
							reject(err);
						});
					}
					//请求完成
				} else {
					handleErr();
				}
			})
			.catch((err) => {
				console.log('newhttps:handleErr')
				handleErr();
			});
		} else {
			reject(err);
		}
	}

	static upload(file, pt) {
		var self = this;
		return new Promise(function (resolve, reject) {
			if (pt == 'product' || pt == 'certificate' || pt == 'from' || pt == 'article' || pt == 'activity') {
				var reader = new FileReader();
				reader.fileName = file.name;
				reader.onload = function () {
					let img = new Image();
					let can = document.createElement('canvas');
					// 读取完成后，将结果复制给img的src
					img.src = this.result;
					img.onload = function () {
						let bytesArray;
						if (img.src.length > 1499990) {
							if (img.width > 1500) {
								var w = 1500 / img.width;
								can.width = 1500;
								can.height = img.height * w;
							} else {
								can.width = img.width;
								can.height = img.height;
							}
							let ctx = can.getContext('2d');
							ctx.drawImage(img, 0, 0, can.width, can.height);
							bytesArray = can.toDataURL('image/jpeg', 0.7);
						} else {
							bytesArray = img.src;
						}
						service
							.post('information/public/upload?fn=' + file.name + '&pt=' + pt + '&ut=base64', bytesArray)
							.then((res) => {
								if (res.data.ok) {
									resolve(res.data.data);
								} else {
									Toast({
										message: '权限不足',
										position: 'middle',
										duration: 2000
									});
								}
							})
							.catch((err) => {
								reject(err);
							});
					};
				};
				reader.readAsDataURL(file);
			} else {
				service
					.post('information/public/upload?fn=' + file.name, file)
					.then((res) => {
						if (res.data.ok === true) {
							resolve(res.data.data);
						} else {
							Toast({
								message: '权限不足',
								position: 'middle',
								duration: 2000
							});
						}
					})
					.catch((err) => {
						if (err.response.status === 401) {
							Toast({
								message: '权限不足',
								position: 'middle',
								duration: 2000
							});
						} else {
							reject(err);
						}
					});
			}
		});
	}

	static uploadfile(file) {
		var self = this;
		return new Promise(function (resolve, reject) {
			var reader = new FileReader();
			reader.fileName = file.name;
			reader.onload = function () {
				let bytesArray = this.result;
				self
					.post(
						fileserver +
						'/api/upload/' +
						md5.hex(bytesArray) +
						'?fn=' +
						reader.fileName +
						'&ec=false&ut=base64',
						bytesArray
					)
					.then((res) => {
						if (res.ok == true) {
							resolve(fileserver + '/api/dl/' + res.data);
						} else {
							reject(res);
						}
					})
					.catch((err) => {
						reject(err);
					});
			};
			reader.readAsDataURL(file);
		});
	}
}

export default http;
