<template>
  <div class="vue-lucky-wheel" :style="style">
    <div
      class="vue-lucky-wheel-main"
      :style="mainStyle">
      <slot/>
    </div>
    <div class="vue-lucky-wheel-button" @click="getPrize">
      <slot name="button">
        <img src="../../cmmon/image/womenDay/an.png" :width="300 / size * 90" height="100%" />
          <span class="residue" v-if='counsIndex!=-1'>剩余{{counsIndex}}次</span>
      </slot>
    </div>
  </div>
</template>

<script>

var wx = require('weixin-js-sdk');
export default {
  name: 'vue-luckywheel',

  props: {
    defaultBackground: {
      type: Boolean,
      default: true
    },

    size: {
      type: Number,
      default: 300
    },

    rotateTime: {
      type: Number,
      default: 6
    },

    prizeIndex: {
      type: Number
    },
    counsIndex: {
      type: Number
    },
    playArea:{
       type: String
    }
  },

  data () {
    return {
      couns:this.$props.couns,
      itemNumber: 0,
      rotateAngle: 0,
      startAngle: 0,
      isDrawing: false,
      gameid:this.$route.query.gameid,
      unionid:this.$route.query.unionid,
      agentid:this.$route.query.agentid,
      index: 0
    }
  },

  computed: {
    style () {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`
      }
    },

    mainStyle () {
      return {
        ...this.style,
        backgroundImage:  `url(${this.playArea})`,
        transform: `rotate(${this.rotateAngle}deg)`,
        transition: `transform ${this.rotateTime-3}s ease-in-out`
      }
    },

    prizeAngleList () {
      let itemNumber = this.itemNumber
      const onePrizeAngle = 360 / itemNumber / 2
      const prizeAngleList = []

      while (itemNumber > 0) {
        prizeAngleList.push(onePrizeAngle + --itemNumber * onePrizeAngle * 2)
      }
      return prizeAngleList
    }
  },
  watch: {
      counsIndex(newValue, oldValue) {

      }
  } ,
  mounted () {
    this.itemNumber = this.$children.length
    this.isuse=true
  },

  methods: {

   async getPrize () {
      if (this.isDrawing) { return }

      this.isDrawing = true
      this.$emit('get-prize')
    },

    draw () {
      if(this.prizeIndex!==1000){
        this.rotateAngle = this.startAngle + 6 * 360 + this.prizeAngleList[this.prizeIndex] - this.startAngle % 360
        this.startAngle = this.rotateAngle
        setTimeout(() => {
          this.isDrawing = false
          this.$emit('game-over')
          this.$parent.popUp();
        }, this.rotateTime * 500)
      }else{
        this.isDrawing = false
        this.$emit('game-over')
      }

    }
  }
}
</script>

<style lang="less" scoped>
.vue-lucky-wheel-main {
      // background-image: url('../../cmmon/image/womenDay/zp.png');
      background-size: cover;
    }

.vue-lucky-wheel {
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  // 必须改变父元素的维度才能包住子元素
  transform: translate3d(0,0,0);
  margin: 0 auto;

  .vue-lucky-wheel-button {
    position: absolute;
    top: 46.5%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.residue{
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom:21%;
  color:#df1d18;
  font-weight: bold;
  font-size: 12px;
  margin-top: -2px;
}
</style>
