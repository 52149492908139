import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'babel-ployfill'
import 'amfe-flexible/index.js'
import './assets/base.less'
import './assets/style/iconfont.css'
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
import Vant from 'vant';
import 'video.js/dist/video-js.css'
import 'vant/lib/index.css';
import '@vant/touch-emulator';
import './cmmon/font/font.css';
import './cmmon/js/autotrack';
import VueLuckywheel from './cmmon/js'
import Carousel3d from 'vue-carousel-3d';


let wx = require("weixin-js-sdk");

// import VueJsonp from 'vue-jsonp'
// import './cmmon/js/customerService';
import htmlToPdf from './cmmon/js/htmlToPdf'
import { VueJsonp } from 'vue-jsonp'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
// 时间插件Moment
import moment from 'moment'
Vue.use(Viewer)

Vue.use(Carousel3d);
Vue.use(VueJsonp);
Vue.use(htmlToPdf);
// Vue.use(VueJsonp)
Vue.use(VueLuckywheel)
Vue.use(Vant);
Vue.use(MintUI)
Vue.config.productionTip = false;
Vue.prototype.$moment = moment

window.confirm = function (message) {
  var iframe = document.createElement("IFRAME");
  iframe.style.display = "none";
  iframe.setAttribute("src", 'data:text/plain,');
  document.documentElement.appendChild(iframe);
  var alertFrame = window.frames[0];
  var result = alertFrame.window.confirm(message);
  iframe.parentNode.removeChild(iframe);
  return result;
}
// 保留2位小数
Vue.prototype.$keepTwo = function (value) {
  if (value) {
    return parseFloat(value).toFixed(2)
  } else {
    return 0
  }
}

Vue.prototype.getISOTime = function (date) {
  let dateTime = new Date(date);
  let y = dateTime.getFullYear(), m = dateTime.getMonth() + 1, d = dateTime.getDate(), hh = dateTime.getHours(),
    mm = dateTime.getMinutes(), ss = dateTime.getSeconds();
  if (String(m).length < 2) {
    m = "0" + String(m)
  }
  if (String(d).length < 2) {
    d = "0" + String(d)
  }
  if (String(hh).length < 2) {
    hh = "0" + String(hh)
  }
  if (String(mm).length < 2) {
    mm = "0" + String(mm)
  }
  if (String(ss).length < 2) {
    ss = "0" + String(ss)
  }
  return String(y) + "-" + String(m) + "-" + String(d) + "T" + String(hh) + ":" + String(mm) + ":" + String(ss) + "+08:00"
}
Vue.prototype.formatDate = function (value){
  const date = new Date(value)
  const y = date.getFullYear()
  let MM = date.getMonth() + 1
  MM = MM < 10 ? '0' + MM : MM
  let d = date.getDate()
  d = d < 10 ? '0' + d : d
  let h = date.getHours()
  h = h < 10 ? '0' + h : h
  let m = date.getMinutes()
  m = m < 10 ? '0' + m : m
  let s = date.getSeconds()
  s = s < 10 ? '0' + s : s
  return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
}
// 浏览器下载视频或图片
Vue.prototype.downLoad = function (url, name, type) {
  var xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'arraybuffer'; // 返回类型blob
  xhr.onload = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      let blob = this.response;
      let downLoadUrl = '';
      // 转换一个blob链接
      if (type === 'rar') { //压缩包
        downLoadUrl = url
      } else {
        // 注: URL.createObjectURL() 静态方法会创建一个 DOMString(DOMString 是一个UTF-16字符串)，
        // 其中包含一个表示参数中给出的对象的URL。这个URL的生命周期和创建它的窗口中的document绑定
        downLoadUrl = window.URL.createObjectURL(new Blob([blob], {
          type: type === 'video' ? 'video/mp4' : 'image/jpeg'
        }));
        // 视频的type是video/mp4，图片是image/jpeg
      }
      let a = document.createElement('a');
      a.download = name;
      a.href = downLoadUrl;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.remove();
    };
  };
  xhr.send()
}
// 判断环境
Vue.prototype.isWx = function () {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return new Promise(resolve => {
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          resolve("mini-wx");
        } else {
          resolve("wx");
        }
      });
    });
  } else {
    return new Promise(resolve => {
      resolve("no-wx");
    });
  }
}

Date.prototype.toLocaleString = function () {
  let h = this.getHours(), m = this.getMinutes(), s = this.getSeconds()
  if (String(h).length < 2) {
    h = '0' + String(h)
  }
  if (String(m).length < 2) {
    m = '0' + String(m)
  }
  if (String(s).length < 2) {
    s = '0' + String(s)
  }
  return this.getFullYear() + '-' + (this.getMonth() + 1) + '-' + this.getDate() + ' ' + String(h) + ':' + String(m) + ':' + String(s)
}
// 2022.08.08
Date.prototype.toymd = function () {
  let y = this.getFullYear(), m = this.getMonth() + 1, d = this.getDate()
  if (String(y).length < 2) {
    y = '0' + String(y)
  }
  if (String(m).length < 2) {
    m = '0' + String(m)
  }
  if (String(d).length < 2) {
    d = '0' + String(d)
  }
  return y + '.' + m + '.' + d
}
// 2022年8月8日
Date.prototype.toymdc = function () {
  let y = this.getFullYear(), m = this.getMonth() + 1, d = this.getDate()
  return y + '年' + m + '月' + d + '日'
}

//全局的输入框限制
Vue.prototype.ValidString = /[`!@#$%^:"{},.\/\\;'·[\]]/im; Vue.prototype.Valid = function (aa) {
  aa = aa.replace(Vue.prototype.ValidString, '');
  return aa;
};

// input去掉前后空格
Vue.prototype.trim = function () {
  return this.replace(/(^\s*)|(\s*$)/g, "")
}

// 自定义指令判断头像是否加载成功，失败使用默认图片
Vue.directive('real-img', async function (el, binding) {
  let imgRUL = binding.value;
  if (imgRUL) {
    let exist = await imageIsExist(imgRUL);
    if (exist) {
      el.setAttribute('src', imgRUL);
    }
  }
});

// 自定义长按事件
Vue.directive('longpress', (el, binding) => {
  // if (typeof binding.value !== 'function') {
  //   throw Error(binding.value + '不是函数')
  // }
  // Make sure expression provided is a function
  if (typeof binding.value !== 'function') {
    // Fetch name of component
    const compName = vNode.context.name;
    // pass warning to console
    let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be`;
    if (compName) { warn += `Found in component '${compName}' `; }
    console.warn(warn);
  }
  let pressTimer = null;
  // 开始按下
  const start = (e) => {
    // 如果是点击事件，不启动计时器，直接返回
    if (e.type === 'click' && e.button !== '0') {
      return;
    }
    if (pressTimer == null) {
      // 创建定时器 （2s之后执行长按功能韩函数）
      pressTimer = setTimeout(() => {
        binding.value(); // 执行长按功能函数
      }, 500);
    }
  };
  // 取消按下
  const cancel = (e) => {
    if (pressTimer != null) {
      clearTimeout(pressTimer);
      pressTimer = null;
    }
  };
  // 添加事件监听器
  el.addEventListener('mousedown', start);
  el.addEventListener('touchstart', start);
  // 取消计时器
  el.addEventListener('click', cancel);
  el.addEventListener('mouseout', cancel);
  el.addEventListener('touchend', cancel);
  el.addEventListener('touchcancel', cancel);
});


// 检测图片是否存在
let imageIsExist = function (url) {
  return new Promise((resolve) => {
    var img = new Image();
    img.onload = function () {
      if (img.complete == true) {
        resolve(true);
        img = null;
      }
    }
    img.onerror = function () {
      resolve(false);
      img = null;
    }
    img.src = url;
  })
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');