<template>
  <div>
    <div class="top-w" v-show="show" @click="backTop" :style="'bottom:' + bottom"></div>
  </div>
</template>
<script>
export default {
  props: ["show", "scrollDiv", "bottom"],
  data() {
    return {};
  },
  methods: {
    backTop() {
      let back = setInterval(() => {
        if (this.scrollDiv.scrollTop) {
          this.scrollDiv.scrollTop -= 100;
        } else {
          clearInterval(back);
        }
      });
    },
  },
};
</script>
<style scope lang='less'>
// 回到顶部
.top-w {
  position: fixed;
  right: 10px;
  bottom: 100px;
  z-index: 2000;
  width: 70px;
  height: 70px;
  background: url("../../cmmon/image/top.png") no-repeat;
  background-size: contain;
  -webkit-tap-highlight-color: transparent;
}
</style>


