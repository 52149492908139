<template>
  <div class="popup">
    <van-popup
      v-model="popupVisible"
      :closeOnClickOverlay="false"
      closeable
      position="bottom"
      :style="{ height: '75%', paddingBottom: '60px' }"
    >
      <div class="title">赠送商品</div>
      <div class="banner">
        <img :src="popupData.banner" alt="">
        <div class="name">
          <p>{{ popupData.explain }}</p>
          <p class="num"><span class="buy_num"><i></i>可购数量<b>{{ popupData.own_count }}</b></span></p>
        </div>
      </div>
      <div class="givepeson">
        <div class="phone">
          <label for="">接收账号</label>
          <input type="number" v-model="toPhone" placeholder="请输入手机号">
        </div>
        <div class="sku-stepper">
          <span class="stepper-l">赠送数量</span>
          <div class="stepper-r">
            <div class="r-m" @click="isRreduce('down')">
              <img src="@/cmmon/image/public/减少.png" alt />
            </div>
            <input type="number" v-model="count" @blur="checkNumber()" />
            <div class="r-m" @click="isRreduce('add')">
              <img src="@/cmmon/image/public/添加.png" alt />
            </div>
          </div>
        </div>
      </div>
      <div class="fixed-btn">
        <div class="btns" @click="determine">
          <van-button round type="info">确定</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Dialog } from "vant";
import http from '@/cmmon/js/http.js'
export default {
  data() {
    return {
      count: 1,
      popupVisible: false,
      popupData: {},
      toPhone: ''
    }
  },
  mounted() {
    
  },
  methods: {
    popup(item) {
      console.log(item)
      this.popupData = item
      this.popupVisible = true
    },
    // 监听输入框的值不能有小数点
    checkNumber() {
      if (!/^\d+$/.test(this.count)) {
        this.count = 1
        this.$toast('请输入整数')
      }
    },
    // 商品数量增减
    isRreduce(type) {
      if (type === 'add') {
        this.count++
      } else {
        this.count === 1 ? (this.count = 1) : this.count--
      }
    },
    // 确定加入购物车按钮
    determine() {
      if (this.toPhone.length !== 11 ) {
        this.$toast('请输入正确手机号')
        return
      }
      let obj = {
        activeId: this.popupData.id,
        count: Number(this.count),
        toPhone: this.toPhone
      }
      http.post('/promotion/give/whitelist', obj).then(res => {
        if (res.ok) {
          Dialog.alert({
            message: res.data,
          }).then(() => {
            // on close
            this.popupData.own_count = this.popupData.own_count - this.count
            this.toPhone = ''
            this.count = 1
          });
        } else {
          Dialog.alert({
            message: res.errmsg,
          }).then(() => {
            // on close
          });
        }
      })
    }
  },
}
</script>


<style lang="less" scoped>
@import url('../../../assets/modifyvant.less');
.van-popup--bottom {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding-top: 15px;
  background: #F5F6FA;
  .title {
    font-size: 16px;
    color: #222222;
    font-weight: bold;
    padding-left: 16px;
  }
  .banner {
    margin: 30px 16px 12px;
    background: #fff;
    border-radius: 12px;
    img {
      width: 100%;
      height: 163px;
      border-radius: 12px 12px 0 0;
    }
    .name {
      padding: 12px 16px 20px;
      p {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .num {
      margin-top: 4px;
    }
    .buy_num {
      display: inline-block;
      position: relative;
      padding: 0 14px 0 25px;
      height: 18px;
      font-size: 12px;
      line-height: 18px;
      color: #FF4446;
      background: rgba(255,68,70,0.12);
      border-radius: 30px;
      i {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 18px;
        height: 18px;
        background: #ccc;
        border-radius: 50%;
        background: url('../../../cmmon/image/index1/dibu/buyNum.png') no-repeat;
        background-size: contain;
      }
      b {
        font-weight: bold;
        margin-left: 4px;
      }
    }
  }
  .givepeson {
    background: #fff;
    margin: 0 16px;
    padding: 0 16px;
    border-radius: 12px;
    .phone {
      font-size: 14px;
      color: #666666;
      border-bottom: 1px solid #F5F6FA;
      padding: 20px 0 16px;
      input {
        margin-left: 30px;
      }
    }
  }
  .sku-stepper {
    padding: 20px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .stepper-l {
      font-size: 14px;
      color: #666666;
    }
    .stepper-r {
      display: flex;
      .r-m {
        width: 24px;
        height: 24px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      input {
        width: 34px;
        height: 22px;
        font-size: 15px;
        margin: 0 3px;
        text-align: center;
        color: #000000;
      }
    }
  }
  .btns {
    width: 100%;
    padding: 0 15px;
    height: 44px;
    margin: 0 auto;
    .van-button {
      margin: 0 auto;
      display: block;
      width: 100%;
      height: 40px;
      line-height: 0px;
      border: none;
      background: linear-gradient(to right, #386FEF, #5094F5);
    }
    .van-button--normal {
      color: #fff;
      font-size: 14px;
      padding: 0;
    }
  }
}
/deep/ .van-sku-row__item--active {
  background-color: #4380f1;
  color: #fff;
}
/deep/ .van-sku-actions .van-button:first-of-type {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
/deep/ .van-button--danger {
  display: none;
}
/deep/ .van-sku-row__item {
  margin: 5px 4px 5px 0px;
}
// 减少
/deep/ .van-stepper__minus {
  width: 22px;
  height: 22px;
  padding: 5px;
  border-radius: 50%;
  // background: url('@/cmmon/image/public/减少.png');
  background-size: contain;
}
/deep/ .van-stepper__minus::before {
  display: none;
}
// 增加
/deep/ .van-stepper__plus {
  width: 22px;
  height: 22px;
  padding: 5px;
  border-radius: 50%;
  // background: url('@/cmmon/image/public/添加.png');
  background-size: contain;
}
/deep/ .van-stepper__plus::after {
  display: none;
}
/deep/ .van-stepper__plus::before {
  display: none;
}
/deep/ .van-sku-row__title {
  padding: 0;
  font-size: 14px;
  font-weight: 600;
}
/deep/ .van-stepper__input {
  width: 34px;
  height: 22px;
  border-radius: 10px;
}
.fixed-btn {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>