
<template>
  <div class="loading">
    <img src="../../cmmon/image/search.png" alt="">
  </div>
</template>
 
<script>
  export default {
    name: 'LOADING',
    data () {
      return {}
    },
  }
</script>
<style scoped lang="less">
  .loading{
    position: fixed;
    top:0;
    left:0;
    z-index:121;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  .loading img{
  	width: 50px;
  	height: 100px;
    margin:50px auto;
  }
</style>