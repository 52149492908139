import axios from 'axios';
import router from '@/router';
import Clipboard from 'clipboard';
import { Toast, Indicator } from 'mint-ui';
import des from '../js/des.js';
import md5 from 'js-md5';
const fileserver = "https://file.caredaily.com";
// 基础接口域名
var baseurl = process.env.VUE_APP_API;
var baseurl2 = process.env.VUE_APP_APIs;
// 基础id
 var applyid='6066db7eeb21203a3cac789c' //会议报名，正式
// var applyid='60793093a949e38ae70ab6e7' //会议报名，测试
// var processtypeid='6094aad1f61b0461eacb414a'//专营店测试
var ticketid='610b794e6919e92a4f7e0146'//券活动测试
var processtypeid='60793093a949e38ae70ab6e7'//专营店正式

var lcappid = 'wx6ce3f6a2e2a5fd54' //领创
// var lcappid = 'wx1f7c5262e6cf4c74' //妍可颜
// 基础网页域名
var FrontEnd = process.env.VUE_APP_FONTEND;

//服务号登录URL
// 凯儿得乐母婴wx1f7c5262e6cf4c74 妍可颜
var wxsvcbase = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+lcappid+'&redirect_uri=';
// 凯儿得乐 云加..
var yunwxsvcbase = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+lcappid+'&redirect_uri=';

// 请求主体对象
const service = axios.create({
	baseURL: baseurl,
	timeout: 60000
});

const d = 'Ckil845@#$%^_=258Lo{,.}';
// 请求提交
service.interceptors.request.use(
	(config) => {
		Indicator.open('加载中...');
		// if(config.url.indexOf('actmonitor')==-1){
		//     Indicator.open("加载中...")
		//   }
		if (sessionStorage.getItem('tokentwo')) {
			// 判断是否存在token，如果存在的话，则每个http header都加上token
			// config.headers.Authorization = JSON.parse(des.decryptByDES(sessionStorage.getItem('tokentwo'),d)).token;
			config.headers.Authorization = sessionStorage.getItem('tokentwo');
		}
		return config;
	},
	(err) => {
		return Promise.reject(err);
	}
);
// 请求响应
service.interceptors.response.use(
	(response) => {
		Indicator.close();
		if (response.data && response.data.errmsg && response.data.errmsg == '系统维护中，请稍后再试') {
			router.push('/system');
		}
		return response;
	},
	(error) => {
		Indicator.close();
		return Promise.reject(error); // 返回接口返回的错误信息
	}
);

// 错误 回到登录页

function handleErr() {
	sessionStorage.clear();
	//重定向
	router.push('/wxlogin');
	// router.push('/login');
}
// 请求对象
class http {
	static baseURL = baseurl;
	static frontEnd = FrontEnd;
	static wxsvcBase = wxsvcbase;
	static yunwxsvcBase = yunwxsvcbase;
	static applyid = applyid;
	static processtypeid = processtypeid;
	static ticketid = ticketid
	static lcappid = lcappid
	// 服务号登录正式
		// 'usercenter/wxlogin&response_type=code&scope=snsapi_userinfo&state=' +

	//跳到进来的页面
	static wxToHref = wxsvcbase +
		baseurl2 +
		'user/public/wx_login&response_type=code&scope=snsapi_userinfo&state=';

	static wxsvc2 = wxsvcbase +
		baseurl2 +
		'user/public/wx_login&response_type=code&scope=snsapi_userinfo&state=' +
		encodeURIComponent(FrontEnd + '#/login')
		 +',';
	static wxsvc = wxsvcbase +
		baseurl +
		'usercenter/wxlogin&response_type=code&scope=snsapi_userinfo&state=' +
		encodeURIComponent(FrontEnd + '#/login') +
		',';
	// 服务号登录测试
	// static wxsvc = yunwxsvcbase +
	// 	baseurl +
	// 	'usercenter/wxlogin&response_type=code&scope=snsapi_userinfo&state=' +
	// 	encodeURIComponent(FrontEnd + '#/login') +
	// 	',';
	// 在线支付
	static proUrl = wxsvcbase +
		baseurl +
		'usercenter/wxlogin&response_type=code&scope=snsapi_userinfo&state=' +
		encodeURIComponent(FrontEnd + '#/promotion/');
	static newproUrl = wxsvcbase +
		baseurl +
		'usercenter/wxlogin&response_type=code&scope=snsapi_userinfo&state=' +
		encodeURIComponent(FrontEnd + '#/ykypromotion/');
	// c端活动
	static Cactivity = wxsvcbase +
		'https://client.api.caredaily.com/' +
		'usercenter/clientinfo&response_type=code&scope=snsapi_userinfo&state=' +
		encodeURIComponent(FrontEnd + '#/newYactivityC');
  	// 转盘端活动线上
	static Cactivitys = wxsvcbase +
		'https://client.api.caredaily.com/' +
		'usercenter/clientinfo&response_type=code&scope=snsapi_userinfo&state=' +
		encodeURIComponent(FrontEnd + '#/womenDay');
		// 转盘端活动测试
	// static Cactivitys = yunwxsvcbase +
	// 	'https://test.hkaspire.net/' +
	// 	'usercenter/clientinfo&response_type=code&scope=snsapi_userinfo&state=' +
	// 	encodeURIComponent('https://test.hkaspire.net/#/womenDay');
		// 转盘端活动本地
	// static Cactivitys = wxsvcbase +
	// 	'https://client.api.caredaily.com/' +
	// 	'usercenter/clientinfo&response_type=code&scope=snsapi_userinfo&state=' +
	// 	encodeURIComponent('http://192.168.200.186:8889/#/womenDay');
// 转盘端兑奖正式
	static Redeem = wxsvcbase +
	'https://client.api.caredaily.com/' +
	'usercenter/clientinfo&response_type=code&scope=snsapi_userinfo&state=' +
	encodeURIComponent(FrontEnd + '#/prize');
// 转盘端兑奖测试
	// static Redeem = yunwxsvcbase +
	// 	'https://test.hkaspire.net/' +
	// 	'usercenter/clientinfo&response_type=code&scope=snsapi_userinfo&state=' +
	// 	encodeURIComponent('https://test.hkaspire.net/#/prize')
// 转盘端兑奖本地
	// static Redeem = wxsvcbase +
	// 	'https://client.api.caredaily.com/' +
	// 	'usercenter/clientinfo&response_type=code&scope=snsapi_userinfo&state=' +
	// 	encodeURIComponent('http://192.168.200.186:8889/#/prize')
	// 兑奖页面
	static Aactivity = wxsvcbase +
		baseurl +
		'usercenter/clientinfo&response_type=code&scope=snsapi_userinfo&state=' +
		encodeURIComponent(FrontEnd + '#/newyearCfirst');
	// 企业号登录 棄用
	// static wxbus="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb9f5faa1228f7d35&redirect_uri=https://client.api.caredaily.com/api/v1/wx/bus&response_type=code&scope=snsapi_base&agentid=305&state="+encodeURIComponent("https://dly.caredaily.com/#/login")+","
	// static wxbus="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb9f5faa1228f7d35&redirect_uri=https://caredaily-order-test.hkaspire.net.cn/api/v1/wx/bus&response_type=code&scope=snsapi_base&agentid=305&state="+encodeURIComponent("https://test.hkaspire.net/#/login")+","

	// static setUrl(str) {
	//   if(str=="newurl"){
	//     service.defaults.baseURL = newurl
	//   }else if(str=="baseurl"){
	//     service.defaults.baseURL=baseurl
	//   }
	// }

	static promotionUrl() {
		return service.defaults.baseURL;
	}

	static setUrl(str) {
		service.defaults.baseURL = str;
	}

	static post(url, data, conf) {
		let that = this
		return new Promise(function (resolve, reject) {
			let refreshData = {};
			if (sessionStorage.getItem('refresh')) {
				refreshData = {
					refresh_token: des.decryptByDES(sessionStorage.getItem('refresh'), d)
				};
			}
			service
				.post(url, data, conf)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					that.errResponse(err, refreshData, resolve, reject, 'post', url, data)
				});
		});
	}

	static get(url) {
		let that = this
		return new Promise(function (resolve, reject) {
			let refreshData = {};
			if (sessionStorage.getItem('refresh')) {
				refreshData = {
					refresh_token: des.decryptByDES(sessionStorage.getItem('refresh'), d)
				};
			}
			service
				.get(url)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					that.errResponse(err, refreshData, resolve, reject, 'get', url)
				});
		});
	}

	static put(url, data) {
		let that = this
		return new Promise(function (resolve, reject) {
			let refreshData = {};
			if (sessionStorage.getItem('refresh')) {
				refreshData = {
					refresh_token: des.decryptByDES(sessionStorage.getItem('refresh'), d)
				};
			}
			service
				.put(url, data)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					that.errResponse(err, refreshData, resolve, reject, 'put', url, data)
				});
		});
	}

	static delete(url) {
		let that = this
		return new Promise(function (resolve, reject) {
			let refreshData = {};
			if (sessionStorage.getItem('refresh')) {
				refreshData = {
					refresh_token: des.decryptByDES(sessionStorage.getItem('refresh'), d)
				};
			}
			service
				.delete(url)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					that.errResponse(err, refreshData, resolve, reject, 'delete', url)
				});
		});
	}

	static patch(url, data) {
		let that = this
		return new Promise(function (resolve, reject) {
			let refreshData = {};
			if (sessionStorage.getItem('refresh')) {
				refreshData = {
					refresh_token: des.decryptByDES(sessionStorage.getItem('refresh'), d)
				};
			}
			service
				.patch(url, data)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					that.errResponse(err, refreshData, resolve, reject, 'patch', url, data)
				});
		});
	}

	// 请求失败公共方法
	static errResponse(err, refreshData, resolve, reject, type, url, data) {
		if (err.response && err.response.status == 401) {
			//如果token过期，用refresh_token请求新token
			service.post('usercenter/refresh', refreshData).then((res) => {
				if (res.data.ok == true) {
					sessionStorage.setItem('tokentwo', res.data.data.token);
					sessionStorage.setItem('refresh', des.encryptByDES(res.data.refresh_token, d));
					//刷新token后重新请求
					if (type === 'get') {
						service.get(url).then((res) => {
							resolve(res.data);
						})
						.catch((err) => {
							reject(err);
						});
					} else if (type === 'post') {
						service.post(url, data).then((res) => {
							resolve(res.data);
						})
						.catch((err) => {
							reject(err);
						});

					} else if (type === 'put') {
						service.put(url, data).then((res) => {
							resolve(res.data);
						})
						.catch((err) => {
							reject(err);
						});
					} else if (type === 'delete') {
						service.delete(url).then((res) => {
							resolve(res.data);
						})
						.catch((err) => {
							reject(err);
						});
					} else if (type === 'patch') {
						service.patch(url, data).then((res) => {
							resolve(res.data);
						}).catch((err) => {
							reject(err);
						});
					}
					//请求完成
				} else {
					console.log('handleErr1')
					handleErr();
				}
			})
			.catch((err) => {
				console.log('handleErr2')
				handleErr();
			});
		} else {
			reject(err);
		}
	}

	static upload(file, pt) {
		var self = this;
		return new Promise(function (resolve, reject) {
			if (pt == 'product' || pt == 'certificate' || pt == 'from' || pt == 'article' || pt == 'activity') {
				var reader = new FileReader();
				reader.fileName = file.name;
				reader.onload = function () {
					let img = new Image();
					let can = document.createElement('canvas');
					// 读取完成后，将结果复制给img的src
					img.src = this.result;
					img.onload = function () {
						let bytesArray;
						if (img.src.length > 1499990) {
							if (img.width > 1500) {
								var w = 1500 / img.width;
								can.width = 1500;
								can.height = img.height * w;
							} else {
								can.width = img.width;
								can.height = img.height;
							}
							let ctx = can.getContext('2d');
							ctx.drawImage(img, 0, 0, can.width, can.height);
							bytesArray = can.toDataURL('image/jpeg', 0.7);
						} else {
							bytesArray = img.src;
						}
						service
							.post('information/public/upload?fn=' + file.name + '&pt=' + pt + '&ut=base64', bytesArray)
							.then((res) => {
								if (res.data.ok) {
									resolve(res.data.data);
								} else {
									Toast({
										message: '权限不足',
										position: 'middle',
										duration: 2000
									});
								}
							})
							.catch((err) => {
								reject(err);
							});
					};
				};
				reader.readAsDataURL(file);
			} else {
				service
					.post('information/public/upload?fn=' + file.name, file)
					.then((res) => {
						if (res.data.ok === true) {
							resolve(res.data.data);
						} else {
							Toast({
								message: '权限不足',
								position: 'middle',
								duration: 2000
							});
						}
					})
					.catch((err) => {
						if (err.response.status === 401) {
							Toast({
								message: '权限不足',
								position: 'middle',
								duration: 2000
							});
						} else {
							reject(err);
						}
					});
			}
		});
	}

	static uploadfile(file) {
		var self = this;
		return new Promise(function (resolve, reject) {
			var reader = new FileReader();
			reader.fileName = file.name;
			reader.onload = function () {
				let bytesArray = this.result;
				self
					// .post(
					// 	fileserver +
					// 	'/api/upload/' +
					// 	md5.hex(bytesArray) +
					// 	'?fn=' +
					// 	reader.fileName +
					// 	'&ec=false&ut=base64',
					// 	bytesArray
					// )
					.post('information/public/upload?fn=' + file.name + '&pt=' + '&ut=base64', bytesArray)
					.then((res) => {
						if (res.ok == true) {
							resolve(res.data);
							// resolve(fileserver + '/api/dl/' + res.data);
						} else {
							reject(res);
						}
					})
					.catch((err) => {
						reject(err);
					});
			};
			reader.readAsDataURL(file);
		});
	}

	// 移动端弹出层滚动不影响body
	static divScroll(layerNode) {
		if (!document.querySelector(layerNode)) return;
		this.popupLayer = document.querySelector(layerNode);
		this.startX = 0;
		this.startY = 0;
		this.popupLayer.addEventListener(
			'touchstart',
			function (e) {
				this.startX = e.changedTouches[0].pageX;
				this.startY = e.changedTouches[0].pageY;
			},
			false
		);

		this.popupLayer.addEventListener(
			'touchmove',
			function (e) {
				e.stopPropagation();
				var deltaX = e.changedTouches[0].pageX - this.startX;
				var deltaY = e.changedTouches[0].pageY - this.startY;
				if (Math.abs(deltaY) < Math.abs(deltaX)) {
					e.preventDefault();
					return false;
				}
				if (this.offsetHeight + this.scrollTop >= this.scrollHeight) {
					if (deltaY < 0) {
						e.preventDefault();
						return false;
					}
				}
				if (this.scrollTop === 0) {
					if (deltaY > 0) {
						e.preventDefault();
						return false;
					}
				}
			},
			false
		);
	}

	// 时间处理 +8
	static getISOTime(date) {
		let dateTime = new Date(date);
		let y = dateTime.getFullYear(),
			m = dateTime.getMonth() + 1,
			d = dateTime.getDate(),
			hh = dateTime.getHours(),
			mm = dateTime.getMinutes(),
			ss = dateTime.getSeconds();
		if (String(m).length < 2) {
			m = '0' + String(m);
		}
		if (String(d).length < 2) {
			d = '0' + String(d);
		}
		if (String(hh).length < 2) {
			hh = '0' + String(hh);
		}
		if (String(mm).length < 2) {
			mm = '0' + String(mm);
		}
		if (String(ss).length < 2) {
			ss = '0' + String(ss);
		}
		return (
			String(y) +
			'-' +
			String(m) +
			'-' +
			String(d) +
			'T' +
			String(hh) +
			':' +
			String(mm) +
			':' +
			String(ss) +
			'+08:00'
		);
		//return String(y) + "-" + String(m) + "-" + String(d) + "T00:00:00+08:00"
	}
	// 获取年月日 时分秒
	static getSecond(date) {
		let dateTime = new Date(date);
		let y = dateTime.getFullYear(),
			m = dateTime.getMonth() + 1,
			d = dateTime.getDate(),
			hh = dateTime.getHours(),
			mm = dateTime.getMinutes(),
			ss = dateTime.getSeconds();
		if (String(m).length < 2) {
			m = '0' + String(m);
		}
		if (String(d).length < 2) {
			d = '0' + String(d);
		}
		if (String(hh).length < 2) {
			hh = '0' + String(hh);
		}
		if (String(mm).length < 2) {
			mm = '0' + String(mm);
		}
		if (String(ss).length < 2) {
			ss = '0' + String(ss);
		}
		return (
			String(y) +
			'-' +
			String(m) +
			'-' +
			String(d) +
			'T' +
			String(hh) +
			':' +
			String(mm) +
			':' +
			String(ss)
		);
	}

	//根据参数获取对应日期
	static getDate(day) {
		let time = new Date();
		time.setDate(time.getDate() + day); //获取Day天后的日期
		let y = time.getFullYear();
		let m = time.getMonth() + 1; //获取当前月份的日期
		let d = time.getDate();
		return y + '/' + m + '/' + d;
	}

	static getMonth(month) {
		let time = new Date();
		time.setDate(time.getDate()); //获取Day天后的日期
		let y = time.getFullYear();
		let m;
		if (time.getMonth() + month + 1 > 12) {
			y = y + 1;
			m = time.getMonth() + month - 11; //获取当前月份的日期 d
		} else {
			m = time.getMonth() + month + 1;
		}
		let d = time.getDate();
		return y + '/' + m + '/' + d;
	}
	// 复制订单编号
	static copy(copy_bt, index, url, tip) {
		let self = this;
		var clipboard = new Clipboard('.' + copy_bt + index, {
			text(trigger) {
				if (url) {
					return url;
				} else {
					return trigger.getAttribute('aria-label');
				}
			}
		});
		clipboard.on('success', (e) => {
			if (!tip) {
				// alert("复制成功");
				Toast('复制成功');
			} else {
				Toast(tip);
			}
			// 释放内存
			clipboard.destroy();
		});
		clipboard.on('error', (e) => {
			// 不支持复制
			alert('该浏览器不支持自动复制');
			// 释放内存
			clipboard.destroy();
		});
	}

	// 监听页面滚动
	static scrollMainFun(scrollDiv) {
		if (scrollDiv.scrollTop > 600) {
			return true;
		} else {
			return false;
		}
	}

	// 排序
	static sortarr(arr, keys) {
		let desc = function (x, y) {
			return x[keys] > y[keys] ? 1 : -1; // 升序
		};
		arr.sort(desc);
		return arr;
	}

	// 检查生日是否正确
    static checkBirthday(card){
        var len = card.length;
        //身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
        if(len == '18'){
          var re_eighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X|x)$/;
          var arr_data = card.match(re_eighteen);
          var year = arr_data[2];
          var month = arr_data[3];
          var day = arr_data[4];
          var birthday = new Date(year+'/'+month+'/'+day);
          return this.verifyBirthday(year,month,day,birthday);
        }
        return false;
    }

    // 校验身份证年龄未满18岁及65岁以下
    static verifyBirthday(year,month,day,birthday){
        let now = new Date();
        let now_year = now.getFullYear();
        let now_month = now.getMonth() + 1;
        let now_date = now.getDate();
        //年月日是否合理
        if(birthday.getFullYear() == year && (birthday.getMonth() + 1) == month && birthday.getDate() == day) {
          //判断年份的范围（18岁到65岁之间)
			let nowYear = now_year - year;
			if (nowYear < 18 || nowYear > 65) { //如果年份小于18或大于65，直接返回false
				return false;
			} else if (nowYear === 18 || nowYear === 65) { //年份等于18时或等于65时，当前月份大于出生月份
				if (now_month > Number(month)) {
					return true;
				} else if (now_month === Number(month)) { //如果月份也相等，则比较日期
					if (now_date > Number(day)) { //年份等于18或等于65，月份相等时，如果当前日期小于出生日期
						return false;
					}
				} else {
					return false;
				}
			}
			return true;
        }
        return false;
    }
}

export default http;
