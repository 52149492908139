import CryptoJS from 'crypto-js'
class des{
    //DES加密
  static encryptByDES(message, key){
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.DES.encrypt(message, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }
//DES解密
  static decryptByDES(ciphertext, key){
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    // direct decrypt ciphertext
    const decrypted = CryptoJS.DES.decrypt({
      ciphertext: CryptoJS.enc.Base64.parse(ciphertext)
    }, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  // 千位逗号
  static toThousands(num) {
    var num = (num || 0).toString(), result = '';
    while (num.length > 3) {
        result = ',' + num.slice(-3) + result;
        num = num.slice(0, num.length - 3);
    }
    if (num) { result = num + result; }
    return result;
  }
  // 万位逗号
  static tenThousands(num) {
    var num = (num || 0).toString(), result = '';
    while (num.length > 4) {
        result = ',' + num.slice(-4) + result;
        num = num.slice(0, num.length - 4);
    }
    if (num) { result = num + result; }
    return result;
  }

  static ChineseThousands(num) {
    var num = (num || 0).toString(), result = '';
    while (num.length > 4) {
        result = '万' ;
        num = num.slice(0, num.length - 4);
    }
    if (num) { result = num + result; }
    return result;
  }
  
}

export default des;