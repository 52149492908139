(function(para) {
    var p = para.sdk_url, n = para.name, w = window, d = document, s = 'script',x = null,y = null;
    if(typeof(w['sensorsDataAnalytic201505']) !== 'undefined') {
        return false;
    }
    w['sensorsDataAnalytic201505'] = n;
    w[n] = w[n] || function(a) {return function() {(w[n]._q = w[n]._q || []).push([a, arguments]);}};
    var ifs = ['track','quick','register','registerPage','registerOnce','trackSignup', 'trackAbtest', 'setProfile','setOnceProfile','appendProfile', 'incrementProfile', 'deleteProfile', 'unsetProfile', 'identify','login','logout','trackLink','clearAllRegister','getAppStatus'];
    for (var i = 0; i < ifs.length; i++) {
      w[n][ifs[i]] = w[n].call(null, ifs[i]);
    }
    if (!w[n]._t) {
      x = d.createElement(s), y = d.getElementsByTagName(s)[0];
      x.async = 1;
      x.src = p;
      x.setAttribute('charset','UTF-8');
      w[n].para = para;
      y.parentNode.insertBefore(x, y);
    }
  })({
    sdk_url: 'https://dly.caredaily.com/SDK/sensorsdata.min.js',
    heatmap_url: 'https://dly.caredaily.com/SDK/heatmap.min.js',
    name: 'sensors',
    max_string_length:1500,
    server_url: 'https://count.chrkd.com:8106/sa?project=default',
    heatmap:{
      // clickmap:'not_collect',
      clickmap:'default',
      scroll_notice_map:'not_collect',
      collect_element:function(element_target){
        if(element_target.getAttribute('sensors-disable')==='true'){
          return true;
        }else{
          return false;
        }
      }
    },
    send_type:'ajax',
    show_log:false,
  });
  // sensors.quick('autoTrack'); 
