<template>
    <div>
        <van-popup
            v-model="addressPopup"
            :close-on-click-overlay="false"
            :style="{ height: '60%' }"
            position="bottom"
        >
            <div class="chooseAddress-w">
                <div class="title-fixed">
                    <p>
                       <span v-if="istitle">为了后期开展区域化，请选择您的所在地区</span> 
                       <span v-else-if='title'>{{ title }}</span> 
                       <span v-else>配送地址</span> 
                        <i @click="closePopup"></i>
                    </p>
                    <div class="address-text">
                        <span class="adr-item" v-for="(item,index) in addrArr" :key="index" @click="chooseAdrItem(item.c, index)">{{ item.n }}</span>
                        <span v-if="addrArr.length === 0">请选择</span>
                    </div>
                </div>
                <div class="address-content2">
                    <div class="address-box">
                        <div
                            class="address-item"
                            v-for="(item, index) in addressList"
                            :key="item.c"
                            :class="{ active: index === activeIndex }"
                            @click="chooseAddress(item)"
                        >
                            <span>{{ item.n }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import newhttps from './../../cmmon/js/newhttps'
export default {
    name: 'addressTemp',
    props: {
        addressPopup: {
            type: Boolean,
            default: false
        },
        addr: {
            type: Array,
            default: []
        },
        sendList: {
            type: Array,
            default: []
        },
        istitle:false,
        regionalScope:'',
        title:''
    },
    data(){
        return{
            activeIndex: null,
            addressList: [],
            addrText: '',
            addrArr: [],
            province: '',
            city: '',
            area: '',
            town: ''
        }
    },
    created() {
        this.addrArr = this.addr
        if (this.addr.length < 4) {
            this.addrArr = []
            this.getAddrLibrary()
        } else {
            this.getAddrLibrary(this.addr[this.addr.length - 2].c, false)
        }
        
    },
    methods:{
        //获取地址库
        getAddrLibrary(root, state) {
            let key = ''
            if (root) {
                key = `?key=${root}`
            } else {
                key = ''
            }
            newhttps.get('storage/public/address/base' + key).then(res =>{
                if (res.data === null) {
                    if (state) {
                        this.$emit('closePopup')
                        this.$emit('getAddr', this.addrArr)
                        this.restData()
                    }
                } else {
                    if (this.sendList.length > 0 && key === '') {
                        this.addressList = this.sendList
                    } else {
                        this.addressList = res.data
                    }
                }
            })
        },
        //点击选择地址
        chooseAddress(item) {
            console.log(item,this.$props.regionalScope,'scope')
            this.addrText += item.n
            if (this.addrArr.length < 4) {
                this.addrArr.push(item)
            }
            if (this.addrArr.length === 4) {
                this.addrArr.splice(3, 1)
                this.addrArr.push(item)
            }
            if(this.$props.regionalScope==1){
                this.$emit('closePopup')
                this.$emit('getAddr', this.addrArr)
                this.restData()
                return
            }
            if(this.$props.regionalScope==2 && item.c.length==4){
                this.$emit('closePopup')
                this.$emit('getAddr', this.addrArr)
                this.restData()
                return
            }
            if(this.$props.regionalScope==3 && item.c.length==6){
                this.$emit('closePopup')
                this.$emit('getAddr', this.addrArr)
                this.restData()
                return
            }
            this.getAddrLibrary(item.c, true)
        },
        // 点击选择好的省市区重新更换
        chooseAdrItem(code, index) {
            if (code.length === 2) {
                this.getAddrLibrary()
                this.addrArr = []
            }
            if (code.length === 4) {
                this.getAddrLibrary(this.addrArr[0].c)
                this.addrArr.splice(1, this.addrArr.length - 1) 
            }
            if (code.length === 6) {
                this.getAddrLibrary(this.addrArr[1].c)
                this.addrArr.splice(2, this.addrArr.length - 1) 
            }
            // this.addrArr.splice(index + 1, this.addrArr.length - 1)
        },
        closePopup() {
            this.$emit('closePopup')
            // this.restData()
        },
        restData() {
            this.addrText = ''
            this.addressList = []
            this.addrArr = []
            this.getAddrLibrary()
        }
    }
}
</script>
<style lang="less" scoped>
.chooseAddress-w {
    background: rgb(255, 255, 255);
    .title-fixed {
        position: fixed;
        background: #fff;
        width: 100%;
        z-index: 10;
        p {
            border-bottom: 1px solid #ececec;
            padding-left: 0.21rem;
            font: bold 0.32rem/0.9rem PingFangSC-Medium;
            text-align: center;
            i {
                display: block;
                width: 20px;
                height: 20px;
                background: url('../../cmmon/image/detele.png') no-repeat;
                background-size: contain;
                float: right;
                margin: 0.21rem 0.3rem 0 0;
            }
        }
        .address-text {
            height: 50%;
            line-height: 0.68rem;
            border-bottom: 1px solid #ececec;
            padding: 0 0.3rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: visible;
            font-size: 12px;
            .adr-item {
                margin-right: 15px;
                color: #53acfe;
            }
        }
    }
    .address-content2 {
        font-size: 12px;
        display: flex;
        padding: 1.7rem 0.24rem 0.2rem 0.3rem;
        // overflow: hidden;
        .address-box {
            height: 8rem;
            margin-right: 5px;
            padding: 10px 10px 60px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }
            &::-webkit-scrollbar-track {
                background: rgb(239, 239, 239);
                border-radius: 2px;
            }
            &::-webkit-scrollbar-thumb {
                background: #bfbfbf;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #333;
            }
            &::-webkit-scrollbar-corner {
                background: #179a16;
            }
            .address-item {
                height: 0.68rem;
                line-height: 0.68rem;
                &.active {
                    color: #53acfe;
                }
                span {
                    display: block;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .ceshis {
                width: 100%;
                height: 100px;
            }
        }
    }
}
</style>

