// // 导出页面为PDF格式
// import html2Canvas from 'html2canvas'
// import JsPDF from 'jspdf'
// export default{
//   install (Vue, options) {
//     Vue.prototype.getPdf = function (ids,tltles) {
//       html2Canvas(document.querySelector('#pdfDom'), {
//         allowTaint: true,
//         scale : 2, //提升画面质量，但是会增加文件大小
//       }).then(function (canvas) {
//         //一页显示，不支持打印
//         // let contentWidth = canvas.width; 
//         // let contentHeight = canvas.height;
//         // var pageHeight = contentWidth /592.28*841.89;
//         // var pageData = canvas.toDataURL('image/jpeg', 0.4);
//         // var pdfWidth = (contentWidth + 10) / 2 * 0.75;
//         // var pdfHeight = (contentHeight + 200) / 2 * 0.75; // 500为底部留白
//         // var imgWidth = pdfWidth;
//         // var imgHeight = (contentHeight / 2 * 0.75); //内容图片这里不需要留白的距离
//         // var pdf = new JsPDF('', 'pt', [pdfWidth, pdfHeight]);
//         // var imgWidth = 595.28;
//         // var imgHeight = 592.28/contentWidth * contentHeight;
//         // pdf.addImage(pageData, 'jpeg', 0, 0, imgWidth, imgHeight);
//         // pdf.save('contract' + new Date().getTime() + '.pdf');

//         let contentWidth = canvas.width
//         let contentHeight = canvas.height-100
//         let pageHeight = contentWidth / 592.28 * 850
//         let leftHeight = contentHeight
//         let position = 0
//         let imgWidth = 595.28
//         let imgHeight = 592.28 / contentWidth * contentHeight-200
//         let pageData = canvas.toDataURL('image/jpeg', 0.7)
//         let PDF = new JsPDF('', 'pt', 'a4')
//         if (leftHeight < pageHeight) {
//           PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
//         } else {
//           while (leftHeight > 0) {
//             PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
//             leftHeight -= pageHeight
//             position -= 841.89
//             if (leftHeight > 0) {
//               PDF.addPage()
//             }
//           }
//         }
//         PDF.save(tltles + new Date().getTime() +  '.pdf')
        

//         // 分页被截断
//       //   let contentWidth = canvas.width; 
//       //   let contentHeight = canvas.height;
//       //   var pageHeight = contentWidth /592.28*841.89;
//       //   var leftHeight = contentHeight;
//       //   var position = 0;
//       //   var pageData = canvas.toDataURL('image/jpeg', 0.4);
//       //   var imgWidth = 595.28;
//       //   var imgHeight = 592.28/contentWidth * contentHeight;
//       //   var pdf = new JsPDF('', 'pt', 'a4');
//       //   if (leftHeight < pageHeight) {
//       //       pdf.addImage(pageData, 'jpeg', 0, 0, imgWidth, imgHeight);
//       //   } else {
//       //       while(leftHeight>0){
//       //           pdf.addImage(pageData,'jpeg',0,position,imgWidth,imgHeight)
//       //           leftHeight -= pageHeight;
//       //           position -=841.89;
//       //           if(leftHeight > 0){
//       //               pdf.addPage();
//       //           }
//       //       }
//       //   }
//       //   pdf.save('contract' + new Date().getTime() + '.pdf');
//       })
//     }
//   }
// } 


// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
export default{
  install (Vue, options) {
    Vue.prototype.getPdf = function () {
      var title = this.htmlTitle
      html2Canvas(document.querySelector('#pdfDom'), {
        allowTaint: true
      }).then(function (canvas) {
        let contentWidth = canvas.width
        let contentHeight = canvas.height
        let pageHeight = contentWidth / 592.28 * 841.89
        let leftHeight = contentHeight
        let position = 0
        let imgWidth = 595.28
        let imgHeight = 592.28 / contentWidth * contentHeight
        let pageData = canvas.toDataURL('image/jpeg', 1.0)
        let PDF = new JsPDF('', 'pt', 'a4')
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= 841.89
            if (leftHeight > 0) {
              PDF.addPage()
            }
          }
        }
        PDF.save(title + '.pdf')
      }
      )
    }
  }
}